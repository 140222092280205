import { createApp } from 'vue'
import './style.css'
import './icons.css'
import {routerFn} from "./core/router";
import {simpleRouteFn} from "./core/router/simple.js";
import {Currency, NumberFormat} from "./core/global/global";
import { createHead } from "@vueuse/head"
import { ready } from "./core/global/google";

function sliderApp(elements){
    return import('./apps/Slider.vue').then(({default: App}) => {
        elements.forEach((element) => {
            //settings are on data attribute
            const jsonId = element.dataset.id;

            if(!document.getElementById('carads_slider_' + jsonId))
            {
                return false;
            }

            const settings = JSON.parse(document.getElementById('carads_slider_' + jsonId).textContent);

            if(!settings)
            {
                return false;
            }

            createApp(App)
                .mixin({
                    data() {
                        return {
                            settings: settings
                        }
                    }
                })
                .mount(element);
        });
    });
}

function searchApp(type){
    return import('./apps/Search.vue').then(({default: App}) => {
        const settings = JSON.parse(document.getElementById('carads_search_settings').textContent);
        const base = settings?.base?.toString() ?? null;
        const mode = settings?.mode?.toString() ?? 'web';

        if(type === 'slow')
        {
            settings.tag = 'a';
        }

        createApp(App)
            .use(type === 'slow' ? simpleRouteFn(base, mode) : routerFn(base, mode))
            .use(createHead())
            .mixin({
                methods: {
                    $n: NumberFormat,
                    $c: Currency
                },
                data() {
                    return {
                        settings: settings
                    }
                }
            })
            .mount(type === 'slow' ? '#app-slow' : '#app');
    });
}

function quickSearchApp(){
    return import('./apps/QuickSearch.vue').then(({default: App}) => {
        const element = document.getElementById('quick_search');
        const settings = JSON.parse(element.dataset.settings);
        createApp(App)
            .mixin({
                data() {
                    return {
                        settings: settings
                    }
                }
            })
            .mount('#quick_search');
    });
}


// wait for document to be ready
(function(window){
    (function docReady(fn) {
        // see if DOM is already available
        if (document.readyState === "complete" || document.readyState === "interactive") {
            // call on next available tick
            setTimeout(fn, 1);
        } else {
            document.addEventListener("DOMContentLoaded", fn);
        }
    }(function (){
        ready();
        if (document.getElementById('app')) {
            searchApp('fast').then(() => { });
        }
        if (document.getElementById('app-slow')) {
            searchApp('slow').then(() => { });
        }

        if (document.getElementById('quick_search')) {
            quickSearchApp().then(() => { });
        }

        if (document.getElementsByClassName('slider').length > 0) {
            sliderApp([...document.getElementsByClassName('slider')]).then(() => { });
        }
    }))
})(window);