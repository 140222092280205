/**
 * @file Google API for google tag manager
 *
 * what i need:
 * add event to google tag manager (window.dataLayer.push)
 **/
function dataLayer(){
    if(window?.dataLayer === undefined){
        window.dataLayer = [];
    }

    return window.dataLayer;
}
export function addEvent(event){
    dataLayer().push(event);
}

export function caradsEvent(event, data){
    console.log('caradsEvent', event, data);
    addEvent({
        event: 'ca-' + event,
        ...(data ?? {})
    });
}

export function ready(){
    document.addEventListener('wpcf7submit', function(e) {
        const target = e.target;
        if (target.classList.contains('wpcf7-form') && !target.classList.contains('ca-no-event')) {
            caradsEvent('form', {
                name: (target.id || target.name || 'unknown') + '-wpcf7'
            })
        }
    });

    document.addEventListener('submit_success', function(e) {
        const target = e.target;
        if (target.classList.contains('elementor-form') && !target.classList.contains('ca-no-event')) {
            caradsEvent('form', {
                name: (target.id || target.name || 'unknown') + '-elementor-form'
            })
        }
    });

    /**
     * Event is added manually in vue component så we remove this but keep it for reference
    (document.querySelectorAll('.ca_cta_before') ?? []).forEach(function(element) {
        element.addEventListener('click', function(event) {
            caradsEvent('phone', {
                name: (element?.innerText?.trim() ?? 'unknown') + '(first-click)'
            })
        });
    });
    */
    (document.querySelectorAll('[href^="tel:"]') ?? []).forEach(function(element) {
        // ignore if element is inside #app-slow or #app (vue app)
        if(element.closest('#app-slow') || element.closest('#app')){
            return;
        }
        element.addEventListener('click', function(event) {
            const name =  (element?.href?.replace('tel:', '') ?? 'unknown');
            caradsEvent('phone', {
                name: name + '(website)',
                data: name
            })
        });
    });

    (document.querySelectorAll('[href^="mailto:"]') ?? []).forEach(function(element) {
        if(element.closest('#app-slow') || element.closest('#app')){
            return;
        }

        element.addEventListener('click', function(event) {
            const name = (element?.href?.replace('mailto:', '')?.trim() ?? 'unknown');

            caradsEvent('email', {
                name: name,
                data: name
            })
        });
    });
    (document.querySelectorAll('a[role="button"][href^="#elementor"]') ?? []).forEach(link => link.addEventListener('click', () => {
        const name = link?.innerText?.trim() ?? 'elementor-button-unknown';
        const href = link?.href?.trim() ?? 'unknown';

        caradsEvent('button', {
            name: name,
            data: href
        });
    }));
}