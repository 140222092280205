import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/:base-biler/:brand/:model/:slug-:id(\\d+)',
        name: 'car',
        components: {
            default: () => import('../../views/car.vue'),
        }
    },
    {
        path: '/:base-biler/:brand/:model/:slug~:id(\\d+)',
        name: 'car2',
        components: {
            default: () => import('../../views/car.vue'),
        },
    },
    {
        path: '/:base-biler/:brand/:model',
        name: 'search-model',
        components: {
            default: () => import('../../views/search.vue'),
        }
    },
    {
        path: '/:base-biler/:brand',
        name: 'search-brand',
        components: {
            default: () => import('../../views/search.vue'),
        }
    },
    {
        path: '/:base-biler',
        name: 'search',
        components: {
            default: () => import('../../views/search.vue'),
        }
    }
];


const hashTagRoute = [
    {
        path: '/:brand/:model/:slug-:id(\\d+)',
        name: 'car',
        components: {
            default: () => import('../../views/car.vue'),
        }
    },
    {
        path: '/:brand/:model/:slug~:id(\\d+)',
        name: 'car2',
        components: {
            default: () => import('../../views/car.vue'),
        },
    },
    {
        path: '/',
        name: 'search',
        components: {
            default: () => import('../../views/search.vue'),
        }
    },
    {
        path: '/:brand/:model',
        name: 'search-model',
        components: {
            default: () => import('../../views/search.vue'),
        }
    },
    {
        path: '/:brand',
        name: 'search-brand',
        components: {
            default: () => import('../../views/search.vue'),
        }
    }
];

export const routerFn = (base, mode = 'hash') => {
    return createRouter({
        history: (mode === 'hash' ? createWebHashHistory(base) : createWebHistory()),
        routes: (mode === 'hash' ? hashTagRoute : routes),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { left: 0, top: 0 };
            }
        }
    });
}