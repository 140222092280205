import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'search',
        components: {
            default: () => import('../../views/search.vue'),
        }
    }
];

export const simpleRouteFn = function(base, mode = 'hash'){
    return createRouter({
        history: (mode === 'hash' ? createWebHashHistory(base): createWebHistory(base)),
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { left: 0, top: 0 };
            }
        }
    });
}